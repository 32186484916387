<template>
  <div class="holder">
    <h2>label: (d) => d.label</h2>
    <p>指定labe字段</p>

    <!-- <h2>
      线的值固定规律 value1,value2,value3... 如果字段不同请自行处理，参考例子1
    </h2>

    <h2>
      width: 600, height: 300, marginTop: 20, marginBottom: 40, marginLeft: 50,
      marginRight: 50,
    </h2>
    <p>指定宽高和四周margin</p>
    <h2>duration: 2000, delay: 40, ease: "easeCubicInOut",</h2>
    <p>指定动画时长，delay，ease特性</p>
    <h2>curveName: "curveLinear",</h2>
    <p>
      画线方式，有两种，一种为curveLinear不带弧度，一种为curveMonotoneX带弧度
    </p>
    <h2>isFromZero: true,</h2>
    <p>最小值是否从0开始。默认为true</p>
    <h2>xTickNumber: 3, yTickNumber: 6,</h2>
    <p>指定x轴和y轴的tick数量</p>
    <h2><span class="new">新</span> isShowMax,isShowMin</h2>
    <p>
      v1.0后更新为：是否展示最大值最小值分开属性，默认为false <br />
      (注:v1.0前使用isShowMinMax合并属性，默认为false)
    </p>
    <h2><span class="new">新</span> customMinTxt,customMaxTxt</h2>
    <p>自定义最大值最小值文案，默认为实际值不替换</p>
    <h2>isShowTxtTips</h2>
    <p>是否展示线图动画的数据提示，默认为true显示</p>
    <h2>lineColors</h2>
    <p>线的颜色，默认字的颜色与线一致</p> -->

    <h1>
      点的分布图
      <span @click="playIn1('refDotDistChart')">入场</span>
      <span @click="playOut('refDotDistChart')">出场</span>
    </h1>
    <dot-dist-chart ref="refDotDistChart" id="refDotDistChart" customClass="dot_dist_chart" />
  </div>
</template>
<script>
import dotDistChart from "@/components/dotDistChart/index_v1.0.vue";
import distData from "./distData.json";
console.log("distData", distData);
export default {
  data: function () {
    return {};
  },
  components: { dotDistChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].DotDistChart(distData.dataArr, {
        label: (d) => d.label,
        dot: (d) => d.dot,
        lineOrigin: distData.lineOrigin,
        lineSlope: distData.lineSlope,
        width: 600,
        height: 300,
        marginTop: 60,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 80,
        xFormat: (d) => d + "%",
        xLabel: "(ROE)",
        yLabel: "(PB)",
        duration: 2000,
        delay: 30,
        ease: "easeExpInOut",
        dotCircleRadius: 3,
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}

.dot_dist_chart {
  margin-bottom: 20px;
  min-height: 200px;
  background: #fff;
  background: -webkit-linear-gradient(top right, #fff, #ccc);
  background: -moz-linear-gradient(top right, #fff, #ccc);
  background: linear-gradient(to bottom left, #fff, #ccc);
  display: inline-block;
  /deep/ .dot_above {
    fill: #1b9ef6;
  }
  /deep/ .dot_below {
    fill: rgb(255, 119, 0);
  }

  /deep/ .tip_box {
    display: inline-block;
    background: rgba(255, 119, 0, 0.7);
    color: #fff;
    font-size: 14px;
    padding: 3px 4px;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border: 5px transparent solid;
      border-top: 6px solid rgba(255, 119, 0, 0.7);
    }
  }
  /deep/ .dot_below.dot_highlight {
    fill: #ff7700;
    stroke: rgba(255, 119, 0, 0.5);
    stroke-width: 4px;
  }

  /deep/ .line_slope {
    stroke: #1b9ef6;
    stroke-dasharray: 4 4;
  }
  /deep/ .axis_x,
  /deep/ .axis_y {
    .text {
      fill: #333;
      font-size: 14px;
    }
  }
}
</style>
