<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotalArr: [],
    };
  },
  methods: {
    async playOut() {
      this.svg
        .selectAll(`.domain,.tick_line,.tick_long_line,.line_slope,.x_label,.y_label,.tip_box`)
        .transition()
        .duration(300)
        .style("opacity", "0");
      this.svg
        .selectAll(`.text,.dot`)
        .transition()
        .duration(200)
        .delay((d, i) => i * 20)
        .ease(d3.easeCircleOut)
        .style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    async DotDistChart(
      data,
      {
        label = (d, i) => i, // given d in data, returns the (ordinal) x-value
        dot = (d) => d, // given d in data, returns the (quantitative) y-value
        lineOrigin = 0, //出发点
        lineSlope = 1, //斜率
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        xFormat = (d) => d, // x轴的自定义后缀前缀等
        yFormat = (d) => d, // y轴的自定义后缀前缀等
        xLabel = "",
        yLabel = "",
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        xTickNumber = 4,
        yTickNumber = 5,
        dotCircleRadius = 0, //默认dot半径为0
        tipWidth = 180,
        tipHeight = 80,
      } = {}
    ) {
      const X = d3.map(data, label);
      const Y = d3.map(data, dot);
      const aboveArr = d3.map(data, (item) => {
        return item.isAboveLine;
      });
      const highlightArr = d3.map(data, (item) => {
        return item.isHighlight;
      });

      if (xDomain === undefined) xDomain = [0, d3.max(X)];
      if (yDomain === undefined) yDomain = [0, d3.max(Y)];

      const xScale = d3.scaleSequential(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange).nice();

      const xAxis = d3.axisBottom(xScale).ticks(xTickNumber).tickFormat(xFormat).tickSizeOuter(4);
      const yAxis = d3.axisLeft(yScale).ticks(yTickNumber).tickFormat(yFormat).tickSizeOuter(4);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //找出线的两个点的位置
      const x1 = 0;
      const y1 = lineOrigin;
      const x2 = d3.max(X);
      const y2 = d3.max(X) * lineSlope + y1;
      const theSlopeLine = svg
        .append("line")
        .attr("class", "line_slope")
        .attr("stroke", "#f00")
        .attr("x1", xScale(x1))
        .attr("y1", yScale(y1))
        .attr("x2", xScale(x1))
        .attr("y2", yScale(y1));
      theSlopeLine.transition().ease(d3[ease]).duration(duration).attr("x2", xScale(x2)).attr("y2", yScale(y2));

      //xLabel,yLabel
      svg
        .append("text")
        .attr("class", "x_label")
        .attr("fill", "#000")
        .attr("x", width - marginRight + 10)
        .attr("y", yScale(0))
        .attr("dy", "0.5em")
        .attr("text-anchor", "start")
        .attr("opacity", 0)
        .text(xLabel)
        .transition()
        .delay(500)
        .duration(600)
        .attr("opacity", 1);
      svg
        .append("text")
        .attr("class", "y_label")
        .attr("fill", "#000")
        .attr("x", xScale(0))
        .attr("y", marginTop)
        .attr("dy", "-0.9em")
        .attr("text-anchor", "end")
        .attr("opacity", 0)
        .text(yLabel)
        .transition()
        .delay(500)
        .duration(600)
        .attr("opacity", 1);

      //y轴坐标轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        });
      axisY.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisY
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * 150)
        .ease(d3.easeCircleOut)
        .duration(300)
        .attr("opacity", 1);

      //x轴坐标轴
      let xRealTick;
      const axisX = svg
        .append("g")
        .attr("class", "axis_x")
        .attr("transform", `translate(0,${height - marginBottom + 1})`)
        .call(xAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
          xRealTick = g.selectAll(".tick")._groups[0].length;
        });

      axisX.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisX.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisX
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * 150)
        .ease(d3.easeCircleOut)
        .duration(600)
        .attr("opacity", 1)
        .attr("text-anchor", (d, i) => {
          if (i == 0) {
            return "start";
          } else if (i > 0 && i < xRealTick - 1) {
            return "middle";
          } else {
            return "end";
          }
        });

      //画线上的点
      const dotCircle = svg
        .append("g")
        .attr("class", `dot_circle_group`)
        .selectAll("circle")
        .data(Y)
        .join("circle")
        .attr("cx", (d, i) => {
          return xScale(X[i]);
        })
        .attr("cy", (d) => yScale(d))
        .attr("r", dotCircleRadius * 0.1)
        .attr("class", (d, i) => {
          const aboveClass = aboveArr[i] ? "dot_above" : "dot_below";
          const highlightClass = highlightArr[i] ? "dot_highlight" : "";
          return `dot ${aboveClass} ${highlightClass}`;
        })
        .attr("fill", "#f00")
        .attr("opacity", "0");
      dotCircle
        .transition()
        .duration(duration)
        .ease(d3[ease])
        .delay((d, i) => i * delay)
        .attr("r", dotCircleRadius)
        .attr("opacity", "1");

      console.log(highlightArr);
      const selfIndex = highlightArr.indexOf(true);
      console.log("selfIndex", selfIndex);
      const tipBox = svg
        .append("foreignObject")
        .attr("width", tipWidth)
        .attr("height", tipHeight)
        .attr("transform", `translate(-${tipWidth / 2}, -${tipHeight / 2})`)
        .attr("x", (d, i) => xScale(X[selfIndex]))
        .attr("y", (d, i) => yScale(Y[selfIndex]))
        .attr("opacity", "0");
      tipBox.append("xhtml:div").attr("class", "tip_box").html("格林美");
      tipBox
        .transition()
        .delay(delay * X.length)
        .duration(600)
        .attr("opacity", "1");

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
